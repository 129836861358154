<template>
  <div class="carValuationRecord">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>

    <!-- confirmation dialog box -->
    <v-dialog v-model="confirmDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to send message
          <b>'{{ data.message }}'</b> to all dealer?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="sendPush()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="confirmDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h1>Mass SMS to all dealers</h1>
    <v-textarea v-model="data.message" placeholder="Message" />
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="openConfirmDialog()">Submit</v-btn>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import postAxios from "../../../services/axios-post";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      confirmDialog: false,
      data: {
        message: "",
        title: "",
      },
      token: "",
      type: null,
      alertMsg: null,
    };
  },
  mounted() {
    this.fetchCredentials();
  },
  methods: {
    openConfirmDialog() {
      this.confirmDialog = true;
    },
    sendPush() {
      const self = this;
      if (self.data.message !== "") {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/actionSystem/sendMassSMSToAllDealers`,
          self.data,
          self.token
        )
          .then(res => {
            console.log(res);
            self.data.message = "";
            self.confirmDialog = false;
            self.showAlert("success", "Mass SMS send successfully");
          })
          .catch(e => {
            self.showAlert("error", "An error has occured.. > " + e);
          });
      }
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
